import gql from 'graphql-tag';

export const GetEpisode = gql`
  query GetEpisode($id: ID!) {
    getEpisode(id: $id) {
      id
      description
      descriptionPlus
      duration
      durationPlus
      episodeNumber
      episodeType
      images {
        url
        height
        width
      }
      media {
        url {
          url
          contentType
          style
        }
        resolution
      }
      mediaPlus {
        url {
          url
          contentType
          style
        }
        resolution
      }
      publishDate
      publishDatePlus
      releaseDate
      seasonID
      seriesID
      title
      titleRaw
      series {
        title
        videoTrailers {
          url
          contentType
        }
      }
    }
  }
`;

export const getSeason = gql`
  query GetSeason($id: ID!) {
    getSeason(id: $id) {
      id
      art19FeedPlusSeasonID
      art19FeedSeasonID
      cinemagraphs {
        url
        contentType
      }
      description
      descriptionPlus
      images {
        url
        height
        width
        source
        style
      }
      seasonNumber
      episodeCount
      seriesID
      title
      createdAt
      updatedAt
      episodes {
        items {
          id
          description
          descriptionPlus
          duration
          durationPlus
          episodeNumber
          episodeType
          publishDate
          publishDatePlus
          releaseDate
          seasonID
          seriesID
          title
          titleRaw
          sourceGuid
          sourceGuidPlus
          createdAt
          updatedAt
        }
        nextToken
      }
      series {
        id
        adFreeEpisodesAvailable
        cinemagraphs {
          url
          contentType
        }
        description
        descriptionPlus
        episodeCount
        episodeCountFull
        episodeCountBonus
        episodeCountTrailer
        exclusive
        exclusiveSeasons
        exclusiveEarlyAccessEpisodes
        exclusiveBonusEpisodes
        featuredEpisode
        featuredSeason
        frequency
        images {
          url
          height
          width
          source
          style
        }
        order
        seasonCount
        seasonality
        slug
        title
        videoTrailers {
          url
          contentType
        }
        createdAt
        updatedAt
        episodes {
          nextToken
        }
        episodeReleases {
          nextToken
        }
        favorites {
          nextToken
        }
        genres {
          nextToken
        }
        hosts {
          nextToken
        }
        seasons {
          nextToken
        }
        contentCollections {
          nextToken
        }
        popularity {
          id
          allTime
          daily
          monthly
          periscopeSeriesID
          weekly
          updatedAt
          createdAt
        }
      }
    }
  }
`;

export const getSeries = gql`
  query GetSeries($id: ID!) {
    getSeries(id: $id) {
      id
      adFreeEpisodesAvailable
      cinemagraphs {
        url
        contentType
      }
      description
      descriptionPlus
      episodeCount
      episodeCountFull
      episodeCountBonus
      episodeCountTrailer
      exclusive
      exclusiveSeasons
      exclusiveEarlyAccessEpisodes
      exclusiveBonusEpisodes
      featuredEpisode
      featuredSeason
      frequency
      images {
        url
        height
        width
        source
        style
      }
      order
      seasonCount
      seasonality
      slug
      title
      videoTrailers {
        url
        contentType
      }
      createdAt
      updatedAt
      episodes {
        items {
          id
          description
          descriptionPlus
          duration
          durationPlus
          episodeNumber
          episodeType
          publishDate
          publishDatePlus
          releaseDate
          seasonID
          seriesID
          title
          titleRaw
          sourceGuid
          sourceGuidPlus
          createdAt
          updatedAt
        }
        nextToken
      }
      episodeReleases {
        items {
          id
          type
          episodeID
          releaseDate
          updatedAt
          seriesID
          createdAt
        }
        nextToken
      }
      favorites {
        items {
          id
          seriesID
          status
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      genres {
        items {
          id
          seriesID
          genreID
          createdAt
          updatedAt
        }
        nextToken
      }
      hosts {
        items {
          id
          seriesID
          hostID
          createdAt
          updatedAt
        }
        nextToken
      }
      seasons {
        items {
          id
          art19FeedPlusSeasonID
          art19FeedSeasonID
          description
          descriptionPlus
          seasonNumber
          episodeCount
          seriesID
          title
          createdAt
          updatedAt
        }
        nextToken
      }
      contentCollections {
        items {
          id
          active
          order
          seriesID
          contentCollectionID
          createdAt
          updatedAt
        }
        nextToken
      }
      popularity {
        id
        allTime
        daily
        monthly
        periscopeSeriesID
        weekly
        updatedAt
        createdAt
      }
    }
  }
`;

export const getCuratedMix = gql`
  query GetCuratedMix($id: ID!) {
    getCuratedMix(id: $id) {
      id
      title
      description
      images {
        url
        height
        width
        source
        style
      }
      imagesInternal {
        url
        height
        width
        source
        style
      }
      createdAt
      updatedAt
      curatedMixEpisodes {
        items {
          id
          curatedMixId
          episodeId
          createdAt
          updatedAt
        }
        nextToken
      }
      curatedMixSeries {
        items {
          id
          curatedMixId
          seriesId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const listAdminScreens = gql`
  query ListAdminScreens(
    $filter: ModelAdminScreenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminScreens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const adminScreenVersionsByStatus = gql`
  query AdminScreenVersionsByStatus(
    $adminScreenID: ID
    $statusUpdatedAt: ModelAdminScreenVersionAdminScreenVersionsByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminScreenVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminScreenVersionsByStatus(
      adminScreenID: $adminScreenID
      statusUpdatedAt: $statusUpdatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        adminScreenID
        adminDisplayName
        updatedAt
        admin
        reason
        futurePublishDate
        publishedDate
        status
        json
        createdAt
        adminScreen {
          id
          updatedAt
          createdAt
        }
      }
      nextToken
    }
  }
`;

export const getAdminScreenVersion = gql`
  query GetAdminScreenVersion($id: ID!) {
    getAdminScreenVersion(id: $id) {
      id
      name
      adminScreenID
      updatedAt
      admin
      reason
      futurePublishDate
      publishedDate
      status
      json
      createdAt
      adminScreen {
        id
        updatedAt
        createdAt
        versions {
          nextToken
        }
      }
    }
  }
`;

export const getDiscoveryScreen = gql`
  query GetDiscoveryScreen($id: ID!) {
    getDiscoveryScreen(id: $id) {
      id
      title
      order
      status
      description
      coverImageUrl
      coverImageInternalUrl
      imageUrl
      imageInternalUrl
      createdAt
      updatedAt
      discoveryScreenRows {
        items {
          id
          title
          rowType
          logicType
          indexType
          order
          discoveryScreenId
          shouldDisplayRanking
          dynamicContentCollectionConfId
          dynamicContentCollectionConf {
            id
            expired
            matchUserLanguage
            matchUserCountry
            matchGenres
            matchObjectTypes
            episodeReleaseDateAfter
            episodeReleaseDateBefore
            pinnedContent {
              objectType
              objectId
            }
          }
          items {
            items {
              id
              order
              series {
                id
                title
                images {
                  url
                  width
                  height
                }
              }
              episode {
                id
                title
                images {
                  url
                  width
                  height
                }
              }
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const accountDeletionByUser = /* GraphQL */ `
  query AccountDeletionByUser(
    $user: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountDeletionRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountDeletionByUser(
      user: $user
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        cognitoStatus
        createdAt
        user
      }
      nextToken
    }
  }
`;
